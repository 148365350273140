import Amplify, { Auth, API, graphqlOperation, Storage } from "aws-amplify"
import {
  Authenticator,
  SignIn,
  ConfirmSignUp,
  ForgotPassword,
} from "aws-amplify-react"
import CustomSignUp from "../../../components/CustomSignUp"
import { updateProject } from "../../../graphql/mutations"

import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import SEO from "../../../components/seo"
import awsExports from "../../../aws-exports"
import { Hub } from "aws-amplify"
import { listProjects } from "../../../graphql/queries"
import { onUpdateRoom } from "../../../graphql/subscriptions"

import AdminMenu from "../../../components/AdminMenu"

import axios from "axios"

Amplify.configure(awsExports)

const SignupLink = props => (
  <div>
    {/* {props.authState} */}
    {props.authState === "signIn" && (
      <div>
        <button onClick={() => props.onStateChange("signUp")}>
          Sign Up here
        </button>
      </div>
    )}
  </div>
)

const MigratePage = () => {
  const [user, setUser] = useState(null)
  const [projects, setProjects] = useState([])
  const [roomCount, setRoomCount] = useState(1)

  useEffect(() => {
    if (!user) {
      getUser()
    }
    fetchProjects()
    const subscription = API.graphql(graphqlOperation(onUpdateRoom)).subscribe({
      next: room => console.log("room update!: ", room),
    })
    console.log("roomcount: ", roomCount)
  }, [])

  async function getUser() {
    try {
      const user = await Auth.currentAuthenticatedUser()
      console.log("user: ", user)
      //check for user pool group
      console.log(
        "User Pool Group: ",
        user.signInUserSession.accessToken.payload["cognito:groups"]
      )
      // console.log("attributes: ", user.attributes)
      console.log(
        "Is Admin: ",
        user.signInUserSession.accessToken.payload["cognito:groups"].includes(
          "Admins"
        )
      )
      setUser(user)
    } catch (err) {
      console.log("Error: ", err)
    }
  }

  Hub.listen("auth", data => {
    switch (data.payload.event) {
      case "signIn":
        console.log("user signed in")
        getUser()
        break
      case "signUp":
        console.log("user signed up")
        break
      case "signOut":
        console.log("user signed out")
        break
      case "signIn_failure":
        console.log("user sign in failed")
        break
      case "configured":
        console.log("the Auth module is configured")
    }
  })

  async function fetchProjects() {
    try {
      const projectData = await API.graphql(
        graphqlOperation(listProjects, {
          filter: { onHubs: { eq: true }, approved: { eq: true } },
          limit: 1000,
        })
      )
      const projects = projectData.data.listProjects.items
      await Promise.all(
        projects.map(async project => {
          if (project.image) {
            const image = await Storage.get(project.image)
            project.image = image
          }
          return project
        })
      )
      setProjects(projects)
    } catch (err) {
      console.log("error fetching projects: ", err)
    }
  }

  function handleAuthStateChange(state) {
    if (state === "signedIn") {
      console.log("running handle auth change")
      getUser()
    }
  }

  async function migrateProject(id, input) {
    try {
      const project = { id: id, mohuniRoomboardId: input }
      const migratedProject = await API.graphql(
        graphqlOperation(updateProject, { input: project })
      )
      console.log("migrated project: ", migratedProject)
      // const newProjectState = projects.filter(project => project.)
      fetchProjects()
    } catch (err) {
      console.log("error featuring project:", err)
    }
  }

  const MohuniRoomboardIdForm = ({ project }) => {
    const [mohuniRoomboardId, setMohuniRoomboardId] = useState(
      project.mohuniRoomboardId ?? null
    )
    return (
      <div>
        <label>Hier die Mohuni Roomboard ID eingeben:</label>
        <input
          type="text"
          value={mohuniRoomboardId}
          onChange={e => setMohuniRoomboardId(e.target.value)}
        />
        <button type="button" onClick={() => migrateProject(project.id, mohuniRoomboardId)}>
          Migrate
        </button>
      </div>
    )
  }

  const ProjectList = props => {
    if (
      props.authState === "signedIn" &&
      user &&
      user.signInUserSession.accessToken.payload["cognito:groups"].includes(
        "Admins"
      )
    ) {
      if (projects.length > 0) {
        return (
          <div>
            <section>
              {projects.map((project, index) => (
                <div
                  key={project.id ? project.id : index}
                  style={{
                    border: project.mohuniRoomboardId
                      ? "2px solid green"
                      : "1px solid black",
                    margin: "1rem",
                  }}
                >
                  <div>
                    <h4>Title: {project.title}</h4>
                    <h5>Abstract: {project.subtitle}</h5>
                    <p>Scene ID: {project.sceneUrl}</p>
                  </div>
                  <MohuniRoomboardIdForm project={project} />
                </div>
              ))}
            </section>
          </div>
        )
      } else {
        return <Link to="/partner/submit">Submit a project</Link>
      }
    } else return <div>You don't have permission to view this page. </div>
  }

  return (
    <section>
      <div className="authenticator">
        <Authenticator
          hideDefault={true}
          onStateChange={handleAuthStateChange()}
        >
          <h1>Migrate Projects</h1>
          <ProjectList />

          <SignIn />
          {/* <CustomSignUp /> */}
          {/* <SignUp signUpConfig={mySignUpConfig} /> */}
          <CustomSignUp />
          <ConfirmSignUp />
          <ForgotPassword />
          <SignupLink />
        </Authenticator>
      </div>
      <SEO title="Admin Dashboard | Migrate Projects" />
      <Link to="/">Go back to the homepage</Link>
    </section>
  )
}

export default MigratePage
